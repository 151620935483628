import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import { ConfigProvider } from  './context/config';
import { configUrl, transform } from  './context/config-init';
import App from './components/App';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <ConfigProvider url={configUrl} transform={transform}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ConfigProvider>
  , document.getElementById('root'));
});
