const cleanup = () => {
  // Remove the #internal_access_token from the URL
  window.history.replaceState(
    {},
    window.document.title,
    window.location.origin
  );
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const authProvider = (config) => ({
  login: ({ internal_access_token }) =>  {
    if (!internal_access_token) {
      window.location.assign(`${config.auth_server}?client_id=${config.bi_client_id}&redirect=${window.location.href}`)
    }
    localStorage.setItem('token', internal_access_token);
    cleanup();
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token');

    if (!token) return Promise.reject();

    const jwt = parseJwt(token);
    if (!jwt) {
      return Promise.reject({ redirectTo: '/admin/login' });
    }
    const now = new Date();

    return now.getTime() > (jwt.exp * 1000) ? Promise.reject({ redirectTo: '/admin/login' }) : Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: params => Promise.resolve(),
});

export default authProvider;
