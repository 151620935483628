import React from 'react';
import { List, Datagrid, BooleanField, TextField, EmailField, DateField } from 'react-admin';

const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="first_name" label="First Name" />
            <TextField source="last_name" label="Last Name" />
            <EmailField source="email" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <TextField source="id" label="SSO uid" />
            <BooleanField source="enabled" />
        </Datagrid>
    </List>
);

export default UserList;
