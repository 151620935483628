import React from 'react';
import { List, Datagrid, TextField, BooleanField, ReferenceField, DateField } from 'react-admin';

const PermissionList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <BooleanField source="need_super_admin" />
            <BooleanField source="default_on" />
            <BooleanField source="display" />
            <TextField source="permissions_group" />
            <TextField source="label" />
            <TextField source="app" />
            <ReferenceField source="parent_id" reference="permission"><TextField source="label" /></ReferenceField>
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </Datagrid>
    </List>
);

export default PermissionList;
