import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import { useConfig } from '../context/config';

import './ForgotPassword.scss';
import '../style/okta-widget.scss';
import styles from "./Login.module.css";

const ForgotPasswordPage = (props) => {

  const { config } = useConfig();
  const history = useHistory();
  const [showForgotPassword, setShowForgetPassword] = useState(true);
  const [showAfterSubmitMessage, setAfterSubmitMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [email, setEmail] = useState('');

  const changeHandler = (e) => { setEmail(e.target.value) };

  const backToLogin = () => { history.push("/") };

  const submitResetPassword = async (e) => {
    e.preventDefault();
    if (email) {
      setShowErrorMessage(false);
      setShowForgetPassword(false);
      setAfterSubmitMessage(true);
      await fetch(new URL('/api/v1/reset_password', config.auth_server), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'email': email, 'app': 'APP_BI_DASHBOARDS'})
      });
    } else {
      setShowErrorMessage(true);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div data-se="auth-container" id="okta-sign-in" className="auth-container main-container no-beacon">
        <div className="okta-sign-in-header auth-header">
          <img src="/images/logo.svg" className="auth-org-logo" alt="" />
          <div data-type="beacon-container" className="beacon-container"></div>
        </div>
        <div className="auth-content">
          <div className="auth-content-inner">
            {/* forgot password form */}
            <div className="forgot-password">
              {showForgotPassword &&
                <form onSubmit={submitResetPassword} name="formAction" action="" data-se="o-form" slot="content" id="form51" className="o-form forgot-password-email-enabled o-form-edit-mode">
                  <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                    <h2 data-se="o-form-head" className="okta-form-title o-form-head">Reset Password</h2>
                    <div className="o-form-error-container" data-se="o-form-error-container">
                      {showErrorMessage &&
                        <div className="o-form-error-container o-form-has-errors" data-se="o-form-error-container">
                          <div>
                            <div className="okta-form-infobox-error infobox infobox-error" role="alert">
                              <span className="icon error-16"></span>
                              <p>We found some errors. Please review the form and make corrections.</p>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                      <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                        <div data-se="o-form-label" className="okta-form-label o-form-label">
                          <label htmlFor="account-recovery-username">Email&nbsp;</label>
                        </div>
                        <div data-se="o-form-input-container" className="o-form-input">
                          <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix">
                            <input type="text" placeholder="" name="username" id="account-recovery-username" defaultValue="" aria-label="" autoComplete="off" data-kpxc-id="account-recovery-username" onChange={changeHandler}></input>
                          </span>
                          {showErrorMessage &&
                            <p id="input-container-error68" className="okta-form-input-error o-form-input-error o-form-explain" style={{display: 'block'}} role="alert">
                              <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>
                              This field cannot be left blank
                            </p>
                          }
                        </div>
                      </div>
                      <button data-se="email-button" className="button button-primary button-wide email-button link-button">Reset Password via Email</button>
                    </div>
                  </div>
                </form>
              }
              {showForgotPassword &&
                <div className="auth-footer">
                  <button className="link help js-back" data-se="back-link" onClick={backToLogin} >Back to Sign In</button>
                </div>
              }
            </div>

            {/* after submit message */}
            {showAfterSubmitMessage &&
              <div className="password-reset-email-sent">
                <form data-se="pwd-reset-email-sent" action="" slot="content" id="form73" className="o-form o-form-edit-mode">
                  <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                    <h2 data-se="o-form-head" className="okta-form-title o-form-head">Email sent!</h2>
                    <p className="okta-form-subtitle o-form-explain" data-se="o-form-explain">Email has been sent to {email} with instructions on resetting your password.</p>
                    <div className="o-form-error-container" data-se="o-form-error-container"></div>
                    <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                      <div>
                        <span className="accessibility-text" role="status">Email sent!</span>
                      </div>
                      <button data-se="back-button" className="button button-primary button-wide link-button" onClick={backToLogin}>Back to Sign In</button>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
