import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3BB0C9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#303030',
      contrastText: 'rgba(255, 255, 255, 0.7)',
    }
  },
});

export default theme;
