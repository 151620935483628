const isProdBuild = process.env.NODE_ENV === 'production';
const apiBaseUrl = (isProdBuild ? window.location.origin : 'http://localhost:8080');
const configUrl = new URL('/api/v1/client-config', apiBaseUrl);

const transform = (config) => {
  config.oidc.redirectUri = window.location.origin;
  config.auth_server = apiBaseUrl;
  config.is_production = isProdBuild;
}

export { configUrl, transform };
