import React from 'react';
import { Create, SimpleForm, BooleanInput, TextInput } from 'react-admin';

const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" type="email" />
            <BooleanInput source="enabled" initialValue={true} />
        </SimpleForm>
    </Create>
);

export default UserCreate;
