import React from 'react';
import { Edit, SimpleForm, BooleanInput, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { ClientIdInput } from './Inputs';

const ClientEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ClientIdInput source="client_id" label="Client Id" />
            <TextInput source="name" />
            <BooleanInput source="enabled" />
            <ArrayInput source="valid_redirect_urls" label="Valid Redirect URLs">
              <SimpleFormIterator>
                <TextInput />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="authority" />
        </SimpleForm>
    </Edit>
);

export default ClientEdit;
