import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField } from 'react-admin';

const AccountList = props => (
    <List {...props}>
        <Datagrid rowClick="toggleSelection">
            <TextField source="name" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <NumberField source="max_users" />
            <NumberField source="max_admins" />
        </Datagrid>
    </List>
);

export default AccountList;
