import { useEffect } from 'react';
import { withAuth } from '@okta/okta-react';

const LogoutPage = (props) => {
  useEffect(() => {
    props.auth.logout('/');
  });

  return null;
}

export default withAuth(LogoutPage);
