import React from 'react';
import { List, Datagrid, ReferenceField, DateField, TextField } from 'react-admin';

const BrandIntelligenceList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="user_id" reference="user"><TextField source="email" /></ReferenceField>
            <ReferenceField source="account_id" reference="account"><TextField source="name" /></ReferenceField>
            <TextField source="user_type" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export default BrandIntelligenceList;
