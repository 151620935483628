import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import qs from 'qs';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import utils from '../common/utils'
import { useConfig } from '../context/config';

import styles from "./Login.module.css";
import "../style/okta-widget.scss";

const getToken = (tokens, type) => {
  return tokens.find((token) => Object.keys(token).includes(type));
}

const handleAuthorizationError = () => {
  console.error("AuthorizationError");
}

const saveOktaTokens = (signIn, tokens) => {
  // Save the tokens for later use, e.g. if the page gets refreshed:
  // Add the token to tokenManager to automatically renew the token when needed
  tokens.forEach(token => {
    if (token.idToken) {
      signIn.authClient.tokenManager.add('idToken', token);
    }
    if (token.accessToken) {
      signIn.authClient.tokenManager.add('accessToken', token);
    }
  });
}

const redirectedFromOkta = (config, setErrors, signIn, client_id, redirect, state) => {
  signIn.authClient.token.parseFromUrl().then(tokens => {
    saveOktaTokens(signIn, tokens);
    const { accessToken, idToken } = tokens.reduce((obj, item) => {
      if (item.accessToken) { obj['accessToken'] = item };
      if (item.idToken) { obj['idToken'] = item };
      return obj;
    }, {});
    getInternalJWTAndRedirect(config, setErrors, accessToken, idToken, client_id, redirect, state);
  },
  err => {
    //handle errors as needed
    console.error(err);
  });
}

const getInternalJWT = async (config, accessToken, idToken, client_id, redirect, state) => {
  const response = await fetch(new URL('/api/v1/token/switch', config.auth_server), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.tokenType} ${accessToken.accessToken}`
    },
    body: JSON.stringify({'client_id': client_id, 'id_token': idToken.idToken, 'redirect_to': redirect, 'state': state })
  });
  return await response.json();
}

const getInternalJWTAndRedirect = async (config, setErrors, accessToken, idToken, client_id, redirect, state) => {
  let internal_jwt = await getInternalJWT(config, accessToken, idToken, client_id, redirect, state)
  if (!internal_jwt || !internal_jwt.success) {
    setErrors({ error_id: internal_jwt.error_id });
  } else {
    window.location.assign(internal_jwt['redirect_to'])
  }
}


const hasActiveSession = async (config, setErrors, signIn, client_id, redirect, state, result) => {
  let accessToken = await signIn.authClient.tokenManager.get('accessToken');
  let idToken = await signIn.authClient.tokenManager.get('idToken');
  if (!accessToken || !idToken) {
    let randomString = utils.generateRandomString();
    let [error, tokens] = await to(signIn.authClient.token.getWithoutPrompt({
      responseType: ['token', 'id_token'],
      scopes: ['openid', 'email', 'profile'],
      state: randomString,
      nonce: randomString
    }));
    if (error) return false;

    saveOktaTokens(signIn, tokens);
    accessToken = getToken(tokens, 'accessToken');
    idToken = getToken(tokens, 'idToken');
  }
  getInternalJWTAndRedirect(config, setErrors, accessToken, idToken, client_id, redirect, state);
  return true;
}

const discoverIdPAndRedirect = async (auth_server, authClient, cookies) => {
  const username = cookies.userToLogin;
  const userExists = await checkIfUserExistsInOkta(auth_server, username)
  if (userExists) {
    redirectUserToLoginIdP(authClient, username);
  } else {
    console.error("Something went wrong with account check.");
    handleAuthorizationError();
  }
}

const redirectUserToLoginIdP = async (authClient, username) => {
  const res = await authClient.webfinger({
    resource: 'acct:' + username,
    rel: 'okta:idp'
  });
  try {
    authClient.token.getWithRedirect({
      scopes: ['openid', 'email', 'profile'],
      idp: res.links[0].properties['okta:idp:id'],
      responseType: ['id_token', 'token']
    });
  } catch (err) {
    console.error(err);
  }
}

const checkIfUserExistsInOkta = async (auth_server, username) => {
  const response = await fetch(new URL('/api/v1/users/check', auth_server), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({'email': username, 'app': 'APP_BI_DASHBOARDS'})
  });
  const j = await response.json();
  return j['exists'];
}

const toBoolean = (str, defaultValue = false) => {
  if (!str) return defaultValue;

  return str.toLowerCase() === 'true';
}

const setExpiringCookie = (setCookie, name, value, seconds) => {
  let expireIn = new Date();
  expireIn.setTime(expireIn.getTime + (seconds * 1000));
  setCookie(name, value, { expires: expireIn });
}

const LoginPage = (props) => {
  const { config } = useConfig();
  const [ errors, setErrors ] = useState({});
  const [ cookies, setCookie ] = useCookies(['userToLogin']);
  const { issuer, clientId, redirectUri, scopes, responseType } = config.oidc;
  const { client_id, redirect, state, idp, recoveryToken, fromLogin, login_hint } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const allowIdP = toBoolean(idp, true);
  const fromIdP = toBoolean(fromLogin);
  let oktaSignInConfig = {
    helpLinks: {
      forgotPassword: '/forgot_password'
    },
    logo: '/images/logo.svg',
    baseUrl: issuer.split('/oauth2')[0],
    clientId,
    redirectUri,
    authParams: {
      issuer,
      responseType,
      display: 'page',
      scopes
    },
    idpDiscovery: {
      requestContext: window.location.href
    },
    features: {
      idpDiscovery: allowIdP
    },
    language: 'en',
    i18n: {
      en: {
        'primaryauth.title': 'Welcome to the Amobee Advertising Platform',   // Changes the sign in text
        'primaryauth.username.placeholder': 'Username',
        'primaryauth.username.tooltip': 'Username',
        'primaryauth.submit': 'Sign in',  // Changes the sign in button
        'password.forgot.email.or.username.placeholder': 'Email',
        'password.forgot.email.or.username.tooltip': 'Email',
        'password.forgot.sendEmail': 'Reset Password via Email',
        'error.auth.lockedOut': 'Your account has been locked for 60 minutes.  Please contact your administrator or your Amobee Support team.'
      }
    },
    colors: {
      brand: '#3BB0C9',
    },
    transformUsername: (username) => {
      // Need to set this cookie so we can make sure that the username that was typed in will match the token user that gets returned
      // Issue was that if the user was redirected to the IDP, but was logged in as a different user, the IDP would return immediately with the wrong token
      setExpiringCookie(setCookie, 'userToLogin', username, 120);
      return username;
    }
  };
  if (recoveryToken) {
    oktaSignInConfig.recoveryToken = recoveryToken;
  }
  if (login_hint) {
    oktaSignInConfig.username = login_hint;
  }
  if (client_id) {
    setExpiringCookie(setCookie, 'client_id', client_id, 120);
  }
  if (redirect) {
    setExpiringCookie(setCookie, 'redirect', redirect, 120);
  }
  if (state) {
    setExpiringCookie(setCookie, 'state', state, 120);
  }
  const signIn = new OktaSignIn(oktaSignInConfig);

  useEffect(() => {
    if (Object.keys(errors).length) {
      return;
    }
    if (signIn.hasTokensInUrl()) {
      redirectedFromOkta(config, setErrors, signIn, cookies.client_id, cookies.redirect, cookies.state);
    } else {
      if (fromIdP && allowIdP) {
        discoverIdPAndRedirect(config.auth_server, signIn.authClient, cookies);
      }
      signIn.authClient.session.get().then(async (res) => {
        // Session exists, show logged in state.
        if (res.status === 'ACTIVE') {
          const successful = await hasActiveSession(config, setErrors, signIn, client_id, redirect, state, res);
          if (successful) return;
        }
        // No session, show the login form
        signIn.renderEl({ el: '#sign-in-widget' },
          () => { }, // Nothing to do in this case, the widget will automatically redirect the user to Okta for authentication, then back to this page if successful.
          (err) => { throw err; }
        );
      });
    }
  }, [config, errors, setErrors, cookies, signIn, client_id, redirect, state, allowIdP, fromIdP]);

  const clearState = () => {
    setErrors({});
    signIn.authClient.signOut()
  }

  return (
    <div className={styles.wrapper}>
      { Object.keys(errors).length === 0 ?
        <div id="sign-in-widget" />
        :
        <div className={styles.login_errors}>
          <div className={styles.box}>
            <div className={styles.header}>
              <img src="/images/logo.svg" className={styles.logo} alt="Amobee" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sorry, something went wrong.</div>
              <p>Please contact our support team with the error code: "{errors.error_id}"</p>
              <p>Or, <Link to={{pathname: "/", search: `?client_id=${cookies.client_id}&redirect=${cookies.redirect}` }} onClick={clearState}>try again</Link>.</p>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default LoginPage;
