import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { createBrowserHistory } from 'history';

import { useConfig } from '../context/config';

import authProvider from './admin/authProvider';
import LoginPage from './admin/LoginPage';
import client from './admin/client';
import user from './admin/user';
import app_brand_intelligence from './admin/app/brand_intelligence';
import permission from './admin/permission';
import account from './admin/account';
import geo from './admin/geo';

import theme from './admin/theme';


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const AdminPage = (props) => {

  const { config } = useConfig();
  const history = createBrowserHistory({ basename: '/admin' });

  return (
    <Admin
       theme={theme}
       history={history}
       dataProvider={simpleRestProvider(`${config.auth_server}/api/v1/admin`, httpClient)}
       authProvider={authProvider(config)}
       loginPage={LoginPage}
     >
      <Resource name="client" {...client} />
      <Resource name="user" {...user} />
      <Resource name="app/brand_intelligence" {...app_brand_intelligence} options={{ label: 'Brand Intelligence' }} />
      <Resource name="permission" {...permission} />
      <Resource name="account" {...account} />
      <Resource name="geo" {...geo} />
    </Admin>
  );
};

export default AdminPage;
