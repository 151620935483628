import React, { useState, useEffect } from 'react';
import { useLogin } from 'react-admin';
import qs from 'qs';


import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ThemeProvider } from '@material-ui/core/styles';
import styles from "./LoginPage.module.css";
import theme from './theme';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const login = useLogin();

  useEffect(() => {
    // Available on window.location.hash: { access_token, expires_in, id_token, internal_access_token, scope, state, token_type }
    const { internal_access_token } = qs.parse(window.location.hash.slice(1));

    // if code is present, we came back from login page
    if (internal_access_token) {
      setLoading(true);
      login({ internal_access_token });
    }
  }, [login]);

  const handleLogin = () => {
    setLoading(true);
    login(); // Do not provide code, just trigger the redirection
  };

  return (
    <div className={styles.wrapper}>
      <ThemeProvider theme={theme}>
        <Card className={styles.card}>
          <div className={styles.avatar}>
            <Avatar className={styles.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <CardActions>
            <Button
              className={styles.button}
              variant="contained"
              type="submit"
              color="primary"
              onClick={handleLogin}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  className={styles.icon}
                  size={18}
                  thickness={2}
                />
              )}
              Login With Okta
            </Button>
          </CardActions>
        </Card>
      </ThemeProvider>
    </div>
  );
};

export default LoginPage;
