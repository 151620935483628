import React from 'react';
import { List, Datagrid, TextField, BooleanField, NumberField } from 'react-admin';

const GeoList = props => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="toggleSelection">
            <TextField source="id" label="Country Code" />
            <TextField source="name" />
            <TextField source="alpha" />
            <BooleanField source="supported" />
            <NumberField source="sample" />
        </Datagrid>
    </List>
);

export default GeoList;
