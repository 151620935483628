import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security } from '@okta/okta-react';

import { useConfig } from '../context/config';
import LoginPage from './Login';
import LogoutPage from './Logout';
import ForgotPasswordPage from "./ForgotPassword";
import AdminPage from "./AdminPage";

import styles from './App.module.css'
import '../style/loader.css';

const customAuthHandler = ({ history }) => {
  // Redirect to the /login page that has a CustomLoginComponent
  history.push('/login');
}

const App = (props) => {
  const { loading, config } = useConfig();

  return (
    <div>
      { loading ?
        <div className={styles.background}>
          <div className="ui active dimmer">
            <div className="ui active big text centered inline elastic loader">Loading</div>
          </div>
        </div>
        :
      <Router>
        <Security
          {...config.oidc}
          onAuthRequired={customAuthHandler}
        >
          <Route path="/" exact={true} component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/forgot_password" component={ForgotPasswordPage} />
        </Security>
        <Route path="/admin" component={AdminPage} />
      </Router> }
    </div>
  );
}

export default App;
