import React from 'react';
import { Create, SimpleForm, BooleanInput, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { ClientIdInput } from './Inputs';

const ClientCreate = props => {
  return (
    <Create {...props}>
        <SimpleForm>
            <ClientIdInput source="client_id" label="Client Id" />
            <TextInput source="name" />
            <BooleanInput source="enabled" initialValue={true} />
            <ArrayInput source="valid_redirect_urls" label="Valid Redirect URLs">
              <SimpleFormIterator>
                <TextInput />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="authority" />
        </SimpleForm>
    </Create>
)};

export default ClientCreate;
