import React from 'react';
import { List, Datagrid, BooleanField, TextField, DateField } from 'react-admin';

const ClientList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="client_id" label="Client Id" />
            <TextField source="name" />
            <BooleanField source="enabled" />
            <TextField source="authority" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export default ClientList;

