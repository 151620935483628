import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ClientList from './ClientList';
import ClientEdit from './ClientEdit';
import ClientCreate from './ClientCreate';

export default {
  list: ClientList,
  edit: ClientEdit,
  create: ClientCreate,
  icon: SettingsApplicationsIcon 
};

