import React from 'react';
import { DateField, useGetList, linkToRecord } from 'react-admin';
import { Tooltip, Box, Card, CardContent, CardHeader, Avatar, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ContentCreate from '@material-ui/icons/Create';
import AppsIcon from '@material-ui/icons/Apps';
import bi_app from '../app/brand_intelligence';
import { makeStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';


const useAsideStyles = makeStyles(theme => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const useAppStyles = makeStyles({
  card: {
    margin: '0 0 1em 1em',
  },
  cardHeader: {
    alignItems: 'flex-start',
  },
  clamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
});

const Aside = ({ record, basePath }) => {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <AppList record={record} basePath={basePath} />}
    </div>
  );
};

const EditButton = ({ record, basePath }) => {
  return (
    <Tooltip title="Edit">
      <IconButton
        component={Link}
        to={linkToRecord(basePath, record && record.id)}
      >
        <ContentCreate />
      </IconButton>
    </Tooltip>
  );
};

const BrandIntelligenceApp = ({ record, basePath }) => {
  const classes = useAppStyles();
  return record ? (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={  <Avatar><bi_app.icon /></Avatar> }
        action={ <EditButton record={record} basePath="/app/brand_intelligence" /> }
        title="Brand Intelligence"
        subheader={
          <Typography variant="body2">{record.created_at}</Typography>
        }
      />
    </Card>
  ) : null;
};

const AppCard = ({ app, basePath }) => {
  switch(app.type) {
    case 'APP_BRAND_INTELLIGENCE':
      return (
        <BrandIntelligenceApp
          record={app.data}
          key={`bi_${app.data.id}`}
          basePath={basePath}
        />
      );
    case 'APP_BI_DASHBOARDS':
      return ( <span /> );
    default:
      return ( <span /> );
  }
};

const concatApps = ( ...appsTuple ) => {
  const merged = appsTuple.map(tuple => {
    const [apps, ids] = tuple;
    return ids.map( id => ({
      type: apps[id].type,
      date: apps[id].created_at,
      data: apps[id]
    }));
  }).flat();
  merged.sort(
    (a1, a2) => new Date(a1.date).getTime() - new Date(a2.date).getTime()
  );
  return merged;
};


const AppList = ({ record, basePath }) => {
  const { data: biApps, ids: biAppsIds } = useGetList(
    'app/brand_intelligence',
    { page: 1, perPage: 100 },
    { field: 'created_at', order: 'DESC' },
    { user_id: record && record.id }
  );

  const apps = concatApps([biApps, biAppsIds]);

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              History
            </Typography>
            <Box display="flex">
              <Box flowGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon
                      fontSize="small"
                      color="disabled"
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      First Seen
                    </Typography>
                    <DateField
                      record={record}
                      source="first_seen"
                    />
                  </Box>
                </Box>
                { apps.length > 0 && (
                    <Box display="flex">
                      <Box mr="1em">
                        <AppsIcon
                          fontSize="small"
                          color="disabled"
                        />
                      </Box>
                      <Box flexGrow={1}>
                        <Typography>
                          { pluralize('apps', apps.length, true) }
                        </Typography>
                      </Box>
                    </Box>
                )}
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon
                      fontSize="small"
                      color="disabled"
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      Last Seen
                    </Typography>
                    <DateField
                      record={record}
                      source="last_seen"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

    { apps.map( app => <AppCard app={app} basePath={basePath} /> ) }

    </>
  ); 
};

export default Aside;
