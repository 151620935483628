import React, { useState, useEffect, useCallback, useContext } from 'react';

const ConfigContext = React.createContext({});

const ConfigProvider = (props) => {
  const { url, transform } = props;
  const [loading, setLoading] = useState(true);
  const [config, setConfig]   = useState({});

  const fetchConfiguration = useCallback(async () => {
    try {
      const configRequest = await fetch(url);
      let configData = await configRequest.json();

      if (transform) transform(configData);

      setConfig(configData);
      setLoading(false);
    } catch (e) {
      if (e) {
        console.log(e.message);
      }
    }
  }, [url, transform, setConfig, setLoading]);

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  return (
    <ConfigContext.Provider value={{
      loading,
      config
    }}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export const useConfig = () => useContext(ConfigContext);
export { ConfigProvider, ConfigContext }
