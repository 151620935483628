import React from 'react';
import { TextInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import { useForm } from 'react-final-form';

import utils from '../../../common/utils';

const useStyles = makeStyles((theme) => ({
  client_id: { display: 'inline-block' },
  button: {
    display: 'inline-block',
    marginLeft: '10px',
    top: '17px',
    "&:hover": {
      color: "#fff",
      background: 'red',
      borderColor: 'red',
    }
  },
}));

const ClientIdInput = (props) => {
  const classes = useStyles();
  const form = useForm();

  const generate = () => {
    form.change(props.source, utils.generateRandomString(20));
  }
  return (
    <>
      <TextInput {...props} formClassName={classes.client_id} />
      <IconButton
        variant="outlined"
        color="primary"
        size="small"
        className={classes.button}
        onClick={generate}
      >
        <CachedIcon />
      </IconButton>
    </>
  );
}

export { ClientIdInput };
