import React from 'react';
import { TextField, DateField } from 'react-admin';
import { Edit, SimpleForm, BooleanInput, TextInput } from 'react-admin';
import Aside from './Aside';


const UserEdit = props => (
    <Edit
        aside={<Aside />}
        {...props}
    >
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" type="email" />
            <BooleanInput source="enabled" />
            <DateField showTime source="created_at" />
            <DateField showTime source="updated_at" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
